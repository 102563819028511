/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    background: #00a0dd; 
    background-color: #f46a1f;
    
}


body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}